export const rankProps = {
  value: 'lvId',
  label: 'levelName',
  checkStrictly: true
}

export const genderList = [
  {
    label: '男',
    value: '1'
  },
  {
    label: '女',
    value: '2'
  }
]

export const addStaffRules = {
  userRealname: [
    { required: true, message: '请输入员工姓名', trigger: 'blur' }
  ],
  userMobile: [
    { required: true, message: '请输入员工手机号', trigger: 'blur' },
    { min: 11, max: 11, message: '输入11位手机号', trigger: 'blur' }
  ],
  gender: [{ required: true, message: '请选择员工性别', trigger: 'blur' }],
  shopId: [{ required: true, message: '请选择员工所在门店', trigger: 'blur' }],
  dpId: [{ required: true, message: '请选择员工所在部门', trigger: 'blur' }],
  // jobLevel: [{ required: true, message: '请选择员工的岗位', trigger: 'blur' }],
  // subJobLevel: [
  //   { required: true, message: '请选择员工的职级', trigger: 'blur' }
  // ],
  userTags: [{ required: true, message: '请输入擅长服务', trigger: 'blur' }],
  joinTime: [
    { required: true, message: '请选择员工入职时间', trigger: 'blur' }
  ],
  userPhoto: [{ required: true, message: '请上传员工头像', trigger: 'blur' }],
  roleIds: [
    { required: true, message: '请选择关联角色', trigger: 'blur' }
  ],
}
