<template>
  <div class="add-staff">
     <breadcrumb :breadcrumbList="breadcrumbList"></breadcrumb>
    <div class="add-staff-content">
       <addForm></addForm>
    </div>

  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import addForm from './cpns/add-form/add-form.vue'
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import { breadcrumbList } from './config'
export default defineComponent({
  props: {

  },
  components: {
    Breadcrumb,
    addForm,
  },
  setup() {


    return {
      breadcrumbList
    }

  }
})
</script>

<style scoped lang="less">
.add-staff-content{
  padding: 20px;
  margin: 20px;
  background-color: #fff;
}
</style>





